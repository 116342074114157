
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Section',

  props: {
    data: Object,
  },
})
