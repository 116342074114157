<template>
  <router-link :to="`/chapter/${chapter.slugPath}`" class="c-chapter-card">
    <div
      class="c-chapter-card__image"
      :style="{ backgroundImage: `url(${coverURL})` }"
    ></div>
    <div class="c-chapter-card__title">
      {{ chapter.name }}
    </div>
    <div class="c-chapter-card__desc">
      {{ chapter.description }}
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useAssets, utils } from '@/core'
export default defineComponent({
  name: 'ChapterCard',

  props: {
    chapter: Object,
  },

  setup(props) {
    const coverURL = ref('')
    const { fetchAsset, getAssetById } = useAssets()
    const { addCloudinaryUrlParams } = utils
    onMounted(async () => {
      if (props.chapter.cover) {
        await fetchAsset(props.chapter.cover)
        const image = getAssetById(props.chapter.cover)
        coverURL.value = addCloudinaryUrlParams(image.url, 'h_150')
      }
    })
    return {
      coverURL,
    }
  },
})
</script>

<style lang="scss">
.c-chapter-card {
  width: calc(50% - 32px);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  padding: rem(16px);
  gap: rem(8px);

  @include bp-down(small) {
    min-width: rem(240px);
    width: rem(240px);
    min-height: rem(200px);
  }

  &__image {
    height: rem(112px);
    border-radius: 12px;
    background-size: cover;
    background-position: center;
  }
  &__title {
    font-size: rem(20px);
    color: $course-card-title;
    font-weight: 700;
    padding: 0 rem(10px);
  }
  &__desc {
    font-size: rem(16px);
    font-weight: 600;
    color: $course-card-desc;
    padding: 0 rem(10px);
  }
}
</style>
