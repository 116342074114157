import useCourseHandler from '@/composables/useCourseHandler'
import { Chapter, ComposeChapters } from 'types/interface/ComposeChapters'
import { computed, ref } from 'vue'
import { useStructures } from '@/core'
import {
  getCourseLessonsProgress,
  getCommonLessonsProgress,
  clearProgress,
} from '@/services/lessonQuestions'

const {
  fetchStructuresNode,
  fetchStructuresChildren,
  getStructuresBySlugPath,
} = useStructures()
const { currentCourse } = useCourseHandler()

const courseParts = computed(() => [currentCourse.value, 'common'])
const chapterList = ref<Chapter[]>([])
const completedLessons = ref<{ [key: string]: number }>({})
const chapters = computed<Chapter[]>(() =>
  chapterList.value.map((chapter) => ({
    ...chapter,
    lessonsCompleted: completedLessons.value[chapter.slugPath] || 0,
  })),
)

const fetchChapters = async () => {
  if (!chapterList.value.length) {
    for await (const part of courseParts.value) {
      await fetchStructuresNode(part)
      await fetchStructuresChildren(part)
      const partChapters = getStructuresBySlugPath(part, true).childrens
      //TODO: This part should be replacead when numChildren will be returned from endpoint
      for await (const chapter of partChapters) {
        await fetchStructuresChildren(chapter.slugPath)
        getStructuresBySlugPath(chapter.slugPath, true)
        chapter.lessonsNum = getStructuresBySlugPath(
          chapter.slugPath,
          true,
        ).childrens.length
      }
      //---END REPLACE---

      chapterList.value = [...chapterList.value, ...partChapters]
    }
  }
  updateProgressStatus()
}

const updateProgressStatus = async () => {
  completedLessons.value = {}
  const savedCourseLessons = await getCourseLessonsProgress()
  const savedCommonLessons = await getCommonLessonsProgress()
  const lessons = [...savedCourseLessons.rows, ...savedCommonLessons.rows]
  lessons.forEach((lesson) => {
    const chapterSlug = lesson.doc.lessonQuestions.slugPath
      .split('/')
      .slice(0, 2)
      .join('/')
    if (!completedLessons.value[chapterSlug]) {
      completedLessons.value[chapterSlug] = 0
    }
    const answeredQuestions = lesson.doc.lessonQuestions.results.filter(
      (result) => !!result.answer,
    )

    if (
      lesson.doc.lessonQuestions.results.length === answeredQuestions.length
    ) {
      completedLessons.value[chapterSlug] += 1
    }
  })
}

const cleanProgressStatus = async () => {
  await clearProgress()
  await updateProgressStatus()
}

export default (): ComposeChapters => ({
  fetchChapters,
  updateProgressStatus,
  cleanProgressStatus,
  chapters,
})
