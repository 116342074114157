<template>
  <ModalWrapper
    v-if="modalContent"
    :title="modalContent.title"
    class="c-modal-media"
  >
    <template v-slot:media>
      <!-- placeholder until our player works for Vue3 -->
      <video v-if="videoSrc" class="c-modal-media__player" controls autoplay>
        <source :src="videoSrc" type="video/mp4" />
      </video>
      <v-zoomer
        v-if="modalContent.media.image"
        :pivot="`image-center`"
        :minScale="1"
        :maxScale="zoomMaxScale"
      >
        <Image
          class="c-modal-media__img"
          :image-id="modalContent.media.image.imageId"
          :alt-text="modalContent.media.image.altText"
        />
      </v-zoomer>
    </template>
  </ModalWrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'

import useModal from '@/composables/useModal'
import ModalWrapper from '@/components/ModalWrapper.vue'
import Image from '@/components/Image.vue'
import { useAssets, utils } from '@/core'

export default defineComponent({
  components: {
    ModalWrapper,
    Image,
  },

  setup() {
    const { modalContent, closeModal } = useModal()
    const { fetchAsset, getAssetById } = useAssets()
    const { addCloudinaryUrlParams } = utils
    const zoomMaxScale = ref(1)

    const videoSrc = ref('')

    onMounted(async () => {
      if (modalContent.value.media.type === 'video') {
        await fetchAsset(modalContent.value.media.video.videoId)
        const video = getAssetById(modalContent.value.media.video.videoId)

        videoSrc.value = addCloudinaryUrlParams(video.url, 'q_auto')
      }

      if (window.matchMedia('(max-width: 1024px)').matches) {
        zoomMaxScale.value = 5
      }
    })

    return {
      modalContent,
      closeModal,
      videoSrc,
      zoomMaxScale,
    }
  },
})
</script>

<style lang="scss">
.c-modal-media {
  background: transparent;
  border-radius: 0;
  max-width: 100%;

  .c-modal-wrapper__header,
  .c-modal-wrapper__content,
  .c-modal-wrapper__footer {
    display: none;
  }
}
.c-modal-media__player {
  width: 100%;
}

.vue-zoomer {
  width: 100vw;
  height: calc(100vh - 4rem);
}

.zoomer {
  .vue-zoomer & {
    display: flex;
  }
}

.c-modal-media__img {
  .vue-zoomer & {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
