import { useAuth, useTenant } from '@/core'
import { ComposeAccessCheck } from 'types/interface/AccessCheck'
import { ConfigEportalProduct } from 'types/interface/Config'
import { ref, computed } from 'vue'

const auth = useAuth()
const { tenant } = useTenant()
const productsAvailable = ref([])
const productsChecked = ref(false)
const coursesAvailable = computed(() =>
  productsAvailable.value.reduce(
    (acc, { product }) => (acc.includes(product) ? acc : [...acc, product]),
    [],
  ),
)
const productsCheck = async (): Promise<void> => {
  const productIds = tenant.value.eportalProducts.map(
    (ep: ConfigEportalProduct): string => ep.id,
  )
  const { products } = await auth.instance().checkAccess(productIds)
  productsChecked.value = true
  tenant.value.eportalProducts.forEach((ep: ConfigEportalProduct): void => {
    if (products.some((p: { id: string }): boolean => p.id === ep.id)) {
      productsAvailable.value.push(ep)
    }
  })
}
const getProductByName = (courseName: string): ConfigEportalProduct =>
  productsAvailable.value.find((product) => product.name === courseName)

export default (): ComposeAccessCheck => ({
  productsAvailable,
  productsChecked,
  coursesAvailable,
  productsCheck,
  getProductByName,
})
