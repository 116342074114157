import {
  CourseProgress,
  LessonQuestions,
} from './../../types/interface/CourseProgress.d'
import { ref, computed } from 'vue'
import { useAuth, useTenant } from '@/core'
import router from '@/router'

const { user } = useAuth()
const { tenant } = useTenant()
const lessonQuestions = ref([])
const bookmarkContent = ref([])

const slugPath = computed(() =>
  Array.isArray(router.currentRoute.value.params.slugPath)
    ? router.currentRoute.value.params.slugPath.join('/')
    : router.currentRoute.value.params.slugPath,
)
const lessonQuestionsID = computed(
  () =>
    `${tenant.value.slug}-${user.value._id}-lesson-questions-${slugPath.value}`,
)

const setLessonQuestions = (lessonQuestionsItem: LessonQuestions): void => {
  const qIndex = lessonQuestions.value.findIndex(
    (lq) => lq.id === lessonQuestionsItem.id,
  )
  if (qIndex !== -1) {
    lessonQuestions.value[qIndex] = lessonQuestionsItem
  } else {
    lessonQuestions.value.push(lessonQuestionsItem)
  }
}

const getLessonQuestions = (id: string): LessonQuestions =>
  lessonQuestions.value.find((lqs) => lqs.id === id)

export default (): CourseProgress => ({
  slugPath,
  lessonQuestions,
  bookmarkContent,
  lessonQuestionsID,
  getLessonQuestions,
  setLessonQuestions,
})
