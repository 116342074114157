import { computed } from 'vue'
import {
  AllLessonsQuestionsDB,
  LessonQuestions,
  LessonQuestionsDB,
} from './../../types/interface/CourseProgress.d'
import DB from '@/core/services/db'
import { useAuth, useTenant } from '@/core'
import useCourseProgress from '@/composables/useCourseProgress'
import useCourseHandler from '@/composables/useCourseHandler'

const db = new DB('core', {})
const { user } = useAuth()
const { tenant } = useTenant()
const { lessonQuestions } = useCourseProgress()
const { currentCourse } = useCourseHandler()
const courseProgressPrefix = computed(
  () =>
    `${tenant.value.slug}-${user.value._id}-lesson-questions-${currentCourse.value}`,
)

const commonProgressPrefix = computed(
  () => `${tenant.value.slug}-${user.value._id}-lesson-questions-common`,
)

const getLessonQuestions = async (id: string): Promise<LessonQuestionsDB> => {
  try {
    const res = await db.getData(id, {})

    return res
  } catch (err) {
    console.error(err)
    return null
  }
}

const getCommonLessonsProgress = async (): Promise<AllLessonsQuestionsDB> => {
  try {
    const res = await db.getAllData({
      include_docs: true,
      startkey: commonProgressPrefix.value,
      endkey: `${commonProgressPrefix.value}\ufff0`,
    })

    return res
  } catch (err) {
    console.error(err)
    return null
  }
}

const getCourseLessonsProgress = async (): Promise<AllLessonsQuestionsDB> => {
  try {
    const res = await db.getAllData({
      include_docs: true,
      startkey: courseProgressPrefix.value,
      endkey: `${courseProgressPrefix.value}\ufff0`,
    })

    return res
  } catch (err) {
    console.error(err)
    return null
  }
}

const saveLessonQuestions = async (
  lessonQuestions: LessonQuestions,
  id: string,
): Promise<string> => {
  try {
    const res = await db.createOrUpdate<LessonQuestionsDB>({
      _id: id,
      lessonQuestions,
    })

    return res.rev
  } catch (err) {
    console.error(err)
    return null
  }
}

const clearProgress = async (): Promise<void> => {
  const courseSavedLessons = await getCourseLessonsProgress()
  const commonSavedLessons = await getCommonLessonsProgress()
  const rows = [...courseSavedLessons.rows, ...commonSavedLessons.rows]
  for await (const row of rows) {
    await db.removeData(row.id)
  }
  lessonQuestions.value = []
}

export {
  getLessonQuestions,
  saveLessonQuestions,
  getCourseLessonsProgress,
  getCommonLessonsProgress,
  clearProgress,
}
