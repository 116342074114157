import { ComposeCourseHandler } from './../../types/interface/CourseHandler.d'
import { ref, computed } from 'vue'
import { useTenant } from '@/core'
import useAccessCheck from './useAccessCheck'

const { coursesAvailable } = useAccessCheck()
const { tenant } = useTenant()
const lsSCKey = 'trafikk-selected-course'
const currentCourse = ref('')
const hasAnyCourse = computed(() => coursesAvailable.value.length > 0)
const hasManyCourses = computed(() => coursesAvailable.value.length > 1)
const setCurrentCourse = (course: string): void => {
  currentCourse.value = course
  saveSelectedCourse(course)
}
const switchCourse = (course: string): void => {
  saveSelectedCourse(course)
  document.location.assign(location.origin)
}
const getSavedSelectedCourse = () => {
  return localStorage.getItem(lsSCKey)
}
const saveSelectedCourse = (course: string): void => {
  localStorage.setItem(lsSCKey, course)
}
const getCourseByName = (
  courseName: string,
): {
  name: string
  cover: string
  uid: string
} => {
  const t = tenant.value.courses[courseName]
  t.uid = courseName

  return t
}

export default (): ComposeCourseHandler => ({
  currentCourse,
  hasAnyCourse,
  hasManyCourses,
  getCourseByName,
  getSavedSelectedCourse,
  setCurrentCourse,
  switchCourse,
})
