<template>
  <div
    v-if="isModalOpen"
    :class="`c-modal c-${modalComponent.toLowerCase()}`"
    @click.self="onBgClick"
  >
    <component :is="modalComponent" />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useHead } from '@vueuse/head'
import useModal from '@/composables/useModal'
import ModalAction from '@/components/ModalAction.vue'
import ModalResults from '@/components/ModalResults.vue'
import ModalMedia from '@/components/ModalMedia.vue'
import ModalConcept from '@/components/ModalConcept.vue'
import ModalQuestion from '@/components/ModalQuestion.vue'

export default defineComponent({
  components: {
    ModalAction,
    ModalResults,
    ModalMedia,
    ModalConcept,
    ModalQuestion,
  },

  setup() {
    const {
      isModalOpen,
      modalComponent,
      modalContent,
      modalOptions,
      closeModal,
    } = useModal()

    useHead({
      meta: [
        {
          name: 'viewport',
          content: computed(
            () =>
              `width=device-width,initial-scale=1.0,user-scalable=${
                isModalOpen.value === true &&
                modalContent.value.media?.type === 'image'
                  ? 'no'
                  : 'yes'
              }`,
          ),
        },
      ],
    })

    const onBgClick = () => {
      if (!modalOptions.value.canEscape) return

      closeModal()
    }

    return {
      isModalOpen,
      modalComponent,
      onBgClick,
      closeModal,
    }
  },
})
</script>

<style lang="scss">
.c-modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: rem(32px) 0;
  background: $modal-outer-bg;
  z-index: 9999;

  &.c-modalmedia {
    background: $modal-outer-media-bg;
  }
}
</style>
