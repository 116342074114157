import { ref } from 'vue'
const isOpen = ref<boolean>(false)

const closeSidebar = (): void => {
  isOpen.value = false
}

const openSidebar = (): void => {
  isOpen.value = true
}

export { isOpen, closeSidebar, openSidebar }
