
import { defineComponent, computed, ref, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import useChapters from '@/composables/useChapters'
import useModal from '@/composables/useModal'
import TheButton from '@/components/TheButton.vue'
import Topbar from '@/components/Topbar.vue'
import ChapterCard from '@/components/ChapterCard.vue'
import { getPreselectedQuestions } from '@/services/results'
import { getSavedBookmarks } from '@/services/bookmarkContent'
import useCourseHandler from '@/composables/useCourseHandler'
import useAccessCheck from '@/composables/useAccessCheck'

export default defineComponent({
  name: 'Home',

  components: {
    TheButton,
    Topbar,
    ChapterCard,
  },

  setup() {
    const { closeModal, setModal } = useModal()
    const router = useRouter()
    const { chapters } = useChapters()
    const { t } = useI18n()
    const { tenant } = useTenant()
    const { coursesAvailable } = useAccessCheck()
    const { currentCourse } = useCourseHandler()

    const preselectedQuestions = ref<number>(null)
    const preselectedLessons = ref<number>(null)

    const amountOfQuestions = computed(() =>
      t('HOME_SAVED_QUESTIONS', [preselectedQuestions.value]),
    )
    const amountOfLessons = computed(() =>
      t('HOME_SAVED_LESSONS', [preselectedLessons.value]),
    )

    const courseAvailable = computed(() =>
      coursesAvailable.value.includes(currentCourse.value),
    )

    const testBtnClick = (): void => {
      setModal('ModalAction', {
        title: 'Prøveeksamen',
        content:
          'Dette er prøveeksamen for alt innhold i kurset. Vær sikker på at du har gått gjennom alle leksjonene før du begynner. Prøven har 45 spørsmål. Du har 2 timer på deg til å fullføre. Det betyr at du har litt mer enn 2,5 minutt til å besvare hvert spørsmål. Du får se resultatet etter at du har fullført hele prøveeksamen. Lykke til!',
        type: 'primary',
        actions: {
          confirm: {
            title: 'Ta prøven',
            on() {
              router.push(`/test/${currentCourse.value}`)
              closeModal()
            },
          },
          cancel: {
            title: 'Nei, gå tilbake',
            on() {
              closeModal()
            },
          },
        },
      })
    }

    useHead({
      title: computed(() => `${tenant.value.name}`),
    })

    onBeforeMount(async () => {
      const preselected = await getPreselectedQuestions()
      preselectedQuestions.value = preselected
        ? preselected.questionIds.length
        : 0
      const preselectedBookmarks = await getSavedBookmarks()
      preselectedLessons.value = preselectedBookmarks
        ? preselectedBookmarks.length
        : 0
    })

    return {
      currentCourse,
      chapters,
      testBtnClick,
      amountOfQuestions,
      amountOfLessons,
      preselectedQuestions,
      preselectedLessons,
      courseAvailable,
    }
  },
})
