<template>
  <div class="c-sidebar-menu">
    <ul class="c-sidebar-menu__wrapper">
      <li
        class="c-sidebar-menu__item"
        :class="[item.class]"
        v-for="item in menu"
        :key="item.title"
      >
        <TheButton
          v-if="showAction(item.showAction)"
          :to="item.to"
          :icon="`chevron-right`"
          :class="{ 'router-link-active': route.path.indexOf(item.to) !== -1 }"
          @click.prevent="closeMenu(item.to)"
        >
          <span>{{ item.title }}</span>
        </TheButton>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useTenant } from '@/core'
import TheButton from '@/components/TheButton.vue'
import useCourseHandler from '@/composables/useCourseHandler'

export default defineComponent({
  name: 'SidebarMenu',

  components: {
    TheButton,
  },

  emits: ['close-menu'],

  setup(props, { emit }) {
    const { locale } = useI18n()
    const route = useRoute()
    const { tenant } = useTenant()
    const { hasManyCourses } = useCourseHandler()
    const menu = computed(() => tenant.value?.menu[locale.value])
    const closeMenu = (to: string): void => {
      emit('close-menu', to)
    }
    const showActions: {
      hasManyCourses: ComputedRef<boolean>
    } = {
      hasManyCourses: hasManyCourses,
    }
    const showAction = (action: 'hasManyCourses'): boolean => {
      if (action) {
        return showActions[action].value
      }

      return true
    }

    return {
      menu,
      route,
      showAction,
      closeMenu,
    }
  },
})
</script>

<style lang="scss">
.c-sidebar-menu__wrapper {
  margin: 0;
  padding: 0;
}

.c-sidebar-menu__item {
  list-style-type: none;

  &.link-settings {
    display: none;

    @include bp(large) {
      display: none;
    }
  }

  &:first-child {
    margin-top: 3rem;
  }
}
</style>
