<template>
  <div class="c-section">
    <ContentRenderer v-if="data.length" :content-items="data" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Section',

  props: {
    data: Object,
  },
})
</script>
