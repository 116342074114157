<template>
  <div class="c-answer">
    <button
      class="c-answer__btn"
      :class="[
        {
          'c-answer__btn--selected': selected,
          'c-answer__btn--selected--correct':
            showResult && selected && answer.correct,
          'c-answer__btn--selected--incorrect':
            showResult && selected && !answer.correct,
          'c-answer__btn--disabled': disabled,
        },
      ]"
    >
      {{ answer.text }}
    </button>
    <Transition>
      <div v-if="showResult && selected" class="c-answer__chip">
        <div class="c-answer__chip--result" v-if="answer.correct">
          <i class="vb-icon vb-icon-check" />{{ $t('RESULT_SUCCESS') }}
        </div>
        <div class="c-answer__chip--result" v-else>
          <i class="vb-icon vb-icon-close" />{{ $t('RESULT_FAIL') }}
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    answer: Object,
    selected: Boolean,
    showResult: Boolean,
    disabled: Boolean,
  },
})
</script>

<style lang="scss">
.c-answer {
  position: relative;
  &__btn {
    @include btnReset();

    display: inline-block;
    width: calc(100% - rem(16px));
    margin: rem(8px);
    padding: rem(12px) rem(16px);
    border-radius: rem(16px);
    box-shadow: 0 0 rem(16px) rem(1px) $answer-shadow;
    line-height: rem(20px);
    text-align: left;
    background-color: $answer-bg;
    border: 2px solid transparent;
    transition: all 0.5s;
    transition-property: border, background-color, color;

    &--selected {
      border: 2px solid $answer-select-border;
      box-shadow: 0 0 rem(16px) rem(3px) $answer-shadow;

      &--correct {
        background-color: $answer-success-bg;
        border-color: $answer-success-bg;
      }
      &--incorrect {
        background-color: $answer-fail-bg;
        border-color: $answer-fail-bg;
        color: $answer-fail-color;
      }
    }

    &--disabled {
      cursor: not-allowed;
    }
  }
  &__chip {
    position: absolute;
    font-size: 0.85rem;
    font-weight: 700;
    left: 0;
    top: 0;
    padding: rem(2px) rem(8px);
    margin-left: rem(8px);
    background-color: white;
    border-radius: rem(6px);
    &--result {
      display: flex;
      align-items: center;
      gap: rem(4px);
    }
    .vb-icon {
      margin-top: 1px;
    }
    .vb-icon-check {
      color: $results-item-success;
    }

    .vb-icon-close {
      color: $results-item-fail;
    }
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
