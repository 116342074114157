import { ref } from 'vue'
import {
  getPreselectedQuestions,
  setPreselectedQuestions,
} from '@/services/results'
import { Question, ComposeSaved } from 'types/interface/ComposeQuestion'

const preselectedQuestions = ref<string[]>([])
const preselectedCorrect = ref<string[]>([])
const preselectedQuestionsRev = ref<string>()

const deleteQuestion = async (questionId: string) => {
  const filteredQuestions = preselectedQuestions.value.filter(
    (q) => q !== questionId,
  )
  const filteredCorrect = preselectedCorrect.value.filter(
    (q) => q !== questionId,
  )
  await setPreselectedQuestions(
    filteredQuestions,
    filteredCorrect,
    preselectedQuestionsRev.value,
  )
  await refreshPreselectedQuestions()
}

//in case some questions were removed or changed id - clear non existing ones so that total numbers are correct
async function sanitizePreselectedQuestions(questionsToDisplay: Question[]) {
  const existningIds = questionsToDisplay.map((q) => q.id)
  const filteredCorrect = preselectedQuestions.value.filter((cId) =>
    existningIds.includes(cId),
  )
  await setPreselectedQuestions(
    existningIds,
    filteredCorrect,
    preselectedQuestionsRev.value,
  )
  await refreshPreselectedQuestions()
}

const refreshPreselectedQuestions = async () => {
  const preselected = await getPreselectedQuestions()
  preselectedQuestionsRev.value = preselected ? preselected._rev : null
  preselectedQuestions.value = preselected ? preselected.questionIds : []
  preselectedCorrect.value = preselected?.correctIds
    ? preselected.correctIds
    : []
}

export default (): ComposeSaved => ({
  refreshPreselectedQuestions,
  sanitizePreselectedQuestions,
  deleteQuestion,
  preselectedQuestions,
  preselectedCorrect,
  preselectedQuestionsRev,
})
