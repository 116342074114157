<template>
  <focus-trap v-model:active="isActive">
    <div :class="['c-modal-wrapper', classes]">
      <TheButton class="c-modal__btn-close" @click="closeModal">
        <div class="c-modal__btn-close-cross">
          <span class="c-modal__btn-close-cross-cross" />
          <span class="c-modal__btn-close-cross-cross" />
        </div>
      </TheButton>
      <div class="c-modal-wrapper__header">
        <TheHeading class="c-modal-wrapper__header-title">{{
          title
        }}</TheHeading>
        <TheButton
          v-if="closeBtn"
          class="vb-icon vb-icon-close"
          @click="closeModal"
          type="icon"
        >
        </TheButton>
      </div>
      <div class="c-modal-wrapper__content">
        <slot name="content" />
      </div>
      <div class="c-modal-wrapper__media">
        <slot name="media" />
      </div>
      <div class="c-modal-wrapper__footer">
        <slot name="footer" />
      </div>
    </div>
  </focus-trap>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useModal from '@/composables/useModal'

import TheHeading from '@/components/TheHeading.vue'

import TheButton from '@/components/TheButton.vue'

export default defineComponent({
  components: {
    TheHeading,
    TheButton,
  },

  props: {
    title: String,
    classes: String,
    closeBtn: Boolean,
  },
  setup() {
    const { closeModal } = useModal()

    return {
      closeModal,
    }
  },
})
</script>

<style lang="scss">
.c-modal-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: rem(16px);
  right: rem(16px);
  max-width: calc($app-max-width - rem(32px));
  margin: 0 auto;
  border-radius: rem(16px);
  background: $modal-bg;
  overflow: auto;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  word-break: break-word;

  &.c-modal-media {
    left: 0;
    right: 0;
  }
}

.c-modal-wrapper__header {
  color: $color-text-2;
  background: linear-gradient($modal-wrapper-header-1, $modal-wrapper-header-2);
  padding: rem(10px) rem(16px) rem(6px);
  display: flex;
  justify-content: space-between;

  .success & {
    background: $results-modal-success;
  }

  .fail & {
    background: $results-modal-fail;
  }
  .vb-icon {
    padding: 0;
    font-size: 2.25rem;
    cursor: pointer;
    margin-left: rem(8px);
    padding-bottom: rem(1px);
    align-self: flex-start;
  }
  .vb-icon-close {
    color: black;
    background-color: white;
  }
}

.c-modal-wrapper__header-title {
  font-size: rem(32px);
  margin: 0;
}

.c-modal-wrapper__content {
  line-height: rem(28px);
  padding: rem(22px) rem(16px) rem(52px);
  overflow-y: auto;
}

.c-modal-wrapper__footer {
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0 rem(16px) rem(16px);
}

.c-modal__btn-close {
  display: none;
  position: absolute;
  top: 0.22rem;
  right: 1rem;
  width: rem(31px);
  height: rem(31px);
  background-color: transparent;
  border-radius: 0;
  padding: 2px;
  z-index: 9999;
  background-color: $color-2;

  .c-modalmedia & {
    display: block;
  }
  &-cross {
    position: absolute;
    top: rem(3px);
    right: rem(4px);
    width: rem(24px);
    height: rem(24px);
    &-cross {
      position: absolute;
      right: 0.625em;
      top: -0.075rem;
      transform: rotate(45deg);
      width: 0.05em;
      height: 1.5em;
      background-color: $modal-close-text;
      color: $modal-close-text;
      border: 1px solid $modal-close-text;
      cursor: pointer;

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
}
</style>
