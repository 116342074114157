
import { defineComponent } from 'vue'
import { isOpen, closeSidebar } from '@/composables/useSidebar'
import { useAuth } from '@/core'
import { useRouter } from 'vue-router'
import TheButton from '@/components/TheButton.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'

export default defineComponent({
  name: 'Sidebar',

  components: {
    TheButton,
    SidebarMenu,
  },

  setup() {
    const auth = useAuth()
    const router = useRouter()
    const closeMenu = (to: string): void => {
      router.push(to)
      closeSidebar()
    }
    const logout = (): void => {
      closeSidebar()
      auth.logout()
    }

    return {
      isOpen,
      closeSidebar,
      closeMenu,
      logout,
    }
  },
})
