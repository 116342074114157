
import { defineComponent, ref, computed } from 'vue'
import useModal from '@/composables/useModal'
import ModalWrapper from '@/components/ModalWrapper.vue'
import TestAnswer from '@/components/TestAnswer.vue'
import TestMedia from '@/components/TestMedia.vue'

import {
  Answer,
  Question,
  QuestionAssets,
} from 'types/interface/ComposeQuestion'
import { useI18n } from 'vue-i18n'

import useSaved from '@/composables/useSaved'
import { setPreselectedQuestions } from '@/services/results'

export default defineComponent({
  name: 'ModalQuestion',
  components: {
    ModalWrapper,
    TestAnswer,
    TestMedia,
  },

  setup() {
    const { modalContent, closeModal, setModal } = useModal()
    const question = modalContent.value.content
    const selectedAnswers = ref<Answer[]>([])
    const questionAnswered = computed(() => selectedAnswers.value.length)
    const { t } = useI18n()
    const {
      deleteQuestion,
      preselectedCorrect,
      preselectedQuestions,
      preselectedQuestionsRev,
    } = useSaved()

    const questionMedia = (question: Question) => {
      return question.assets.find(
        (asset: QuestionAssets) =>
          asset.type === 'video' || asset.type === 'image',
      )
    }

    const answerSelected = async (question: Question, answer: Answer) => {
      if (questionAnswered.value) return
      selectedAnswers.value.push(answer)
      if (!answer.correct) {
        if (preselectedCorrect.value.includes(question.id)) {
          preselectedCorrect.value = preselectedCorrect.value.filter(
            (id) => id !== question.id,
          )
          setPreselectedQuestions(
            preselectedQuestions.value,
            preselectedCorrect.value,
            preselectedQuestionsRev.value,
          )
        }
        selectedAnswers.value.push(...question.answers.filter((a) => a.correct))
      }

      setTimeout(() => {
        setModal('ModalResults', {
          title: 'Svar',
          content: question,
          type: 'savedQuestion',
          success: answer.correct,
          actions: {
            confirm: {
              title: answer.correct
                ? t('RESULTS_MODAL_BTN_REMOVE_FROM_SAVED')
                : t('RESULTS_MODAL_BTN_TEXT_FAIL'),
              on() {
                if (answer.correct) {
                  deleteQuestion(question.id)
                }
                closeModal()
              },
            },
          },
        })
      }, 500)
    }

    const isSelected = (answer: Answer) => {
      return selectedAnswers.value.includes(answer)
    }

    return {
      modalContent,
      closeModal,
      question,
      questionMedia,
      answerSelected,
      isSelected,
      questionAnswered,
    }
  },
})
