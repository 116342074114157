
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheHeading',

  props: {
    level: {
      type: String,
      default: 'h2',
    },
  },
})
