<template>
  <component :is="level" class="c-heading">
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheHeading',

  props: {
    level: {
      type: String,
      default: 'h2',
    },
  },
})
</script>

<style lang="scss">
h1 {
  font-size: rem(32px);
}
</style>
