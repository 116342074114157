<template>
  <component
    :is="currentComponent"
    class="c-button"
    :class="[
      `c-button--${type}`,
      {
        'c-button--disabled': disabled,
        'c-icon-button': icon,
        'c-button--hidden': hidden,
      },
    ]"
    v-bind="attributes"
    :disabled="disabled"
  >
    <i
      v-if="icon"
      :class="[iconSet, `${iconSet}-${icon}`, `align-${iconPos}`]"
    ></i>
    <slot />
  </component>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'TheButton',

  props: {
    to: { type: [String, Object] },
    type: {
      type: String,
      default: 'basic',
    },
    icon: {
      type: String,
      default: null,
    },
    iconPos: {
      type: String,
      default: 'left',
    },
    iconSet: {
      type: String,
      default: 'vb-icon',
    },
    hidden: Boolean,
    disabled: Boolean,
  },

  setup(props) {
    const currentComponent = computed(() => {
      if (props.to) {
        return 'router-link'
      } else {
        return 'button'
      }
    })
    const attributes = computed(() => {
      if (currentComponent.value === 'router-link') {
        return {
          to: props.to,
        }
      } else {
        return {}
      }
    })

    return {
      attributes,
      currentComponent,
    }
  },
})
</script>

<style lang="scss">
.c-button {
  @include btnReset();

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  padding: $button-padding;
  line-height: 1.25rem;
  border-radius: $button-border-radius;
  background-color: transparent;
  text-align: center;
  touch-action: manipulation;

  &--basic {
    background-color: $btn-basic-bg;
  }
  &--inline {
    background: none;
    padding: 0;
    font-weight: 400;
    display: inline;
    font-size: inherit;
    line-height: inherit;
    border: none;
  }

  &--primary {
    background: linear-gradient(
      $btn-primary-bg-color-1,
      $btn-primary-bg-color-2
    );
    color: $btn-primary-text !important;
    box-shadow: 0 0.25rem rem(10px) $btn-primary-outline;
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 0.25rem rem(16px) $btn-primary-outline;
    }
    &:disabled {
      background: linear-gradient(
        $btn-primary-disabled-bg-color-1,
        $btn-primary-disabled-bg-color-2
      );
      box-shadow: none;
    }
  }

  &--primary-alt {
    background-color: $btn-primary-alt-bg;
    color: $btn-primary-alt-text !important;
    border: 2px solid $btn-primary-alt-border;

    &:disabled {
      color: $btn-primary-disabled-bg-color-1 !important;
      border: 2px solid $btn-primary-disabled-bg-color-1;
      box-shadow: none;
    }
  }

  &--secondary {
    background-color: $btn-secondary-bg;
    color: $btn-secondary-text !important;
    border: 2px solid $btn-secondary-border;
  }
  &--danger {
    background-color: $btn-danger-bg;
    color: $btn-secondary-text !important;
    border: 2px solid $btn-danger-bg;
  }
  &--audio-section {
    padding: rem(13px) rem(15px);
    box-shadow: 0 rem(4px) rem(16px) $btn-audio-section-shadow;

    > i {
      color: $btn-audio-section-icon;
      margin-right: rem(6px);
      line-height: 0;
    }
  }
  &--disabled {
    cursor: not-allowed;
  }
  &--hidden {
    visibility: hidden;
  }

  &.c-icon-button {
    justify-content: space-around;
  }

  > i {
    font-size: $button-icon-size;
    &.align-right {
      margin-left: rem(5px);
      order: 1;
    }
  }

  .c-sidebar-menu & {
    @include a11yOutlineAltWithOffset();

    justify-content: space-between;
    padding: 1rem 2.5rem 1rem 1.5rem;
    background: none;

    > i {
      order: 2;
      font-size: 1rem;
    }
  }
}
</style>
