<template>
  <ModalWrapper
    v-if="modalContent"
    :title="title"
    :close-btn="true"
    class="c-modal-concept"
  >
    <template v-slot:content>
      <ContentRenderer
        v-if="contentItems.length"
        :content-items="contentItems"
      />
    </template>
  </ModalWrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref } from 'vue'
import { useStructures, useContents } from '@/core'
import useModal from '@/composables/useModal'
import ModalWrapper from '@/components/ModalWrapper.vue'
import ContentRenderer from '@/components/ContentRenderer.vue'

export default defineComponent({
  name: 'ModalConcept',
  components: {
    ModalWrapper,
    ContentRenderer,
  },

  setup() {
    const { modalContent, closeModal } = useModal()
    const {
      fetchStructuresChildren,
      getStructuresChildrens,
      getStructuresById,
      fetchStructuresNode,
    } = useStructures()
    const { fetchObject } = useContents()
    const title = ref<string>('')

    const contentItems = computed(() =>
      getStructuresChildrens(modalContent.value.content, 'OBJECT'),
    )

    onMounted(async () => {
      await fetchStructuresNode(modalContent.value.content)
      title.value = getStructuresById(modalContent.value.content).name
      await fetchStructuresChildren(modalContent.value.content, {
        limit: 100,
        filter: `{"type": "OBJECT"}`,
      })

      contentItems.value.forEach((ci: { contentId: string }) => {
        if (ci.contentId) {
          fetchObject(ci.contentId)
        }
      })
    })
    return {
      modalContent,
      closeModal,
      contentItems,
      title,
    }
  },
})
</script>

<style lang="scss">
.c-modal-concept {
  .c-modal-wrapper__header {
    background: none;
    color: $color-text;
    padding: 1rem;
  }

  .c-modal-wrapper__content {
    padding: 0 1rem;
  }
}
</style>
