<template>
  <router-view v-if="appInitialized" :key="$route.path" />
  <Sidebar />
  <Modal />
</template>

<script lang="ts">
import { ref, defineComponent, onBeforeMount, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import Modal from '@/components/Modal.vue'
import Sidebar from '@/components/Sidebar.vue'
import useChapters from '@/composables/useChapters'
import { useAuth, useTenant } from '@/core'
import {
  getCourseLessonsProgress,
  getCommonLessonsProgress,
} from '@/services/lessonQuestions'
import useCourseProgress from './composables/useCourseProgress'
import DB from '@/core/services/db'
import useSWUpdate from '@/composables/useSWUpdate'
import useModal from '@/composables/useModal'
import useAccessCheck from '@/composables/useAccessCheck'
import useCourseHandler from '@/composables/useCourseHandler'

export default defineComponent({
  components: {
    Modal,
    Sidebar,
  },

  props: {
    tenantConfig: Object,
  },

  setup(props) {
    const { updateExists, refreshApp, initSWUpdateWatch } = useSWUpdate()
    const { initTenant } = useTenant()
    const { fetchChapters } = useChapters()
    const { user, instance, setToken, setUser } = useAuth()
    const { setLessonQuestions } = useCourseProgress()
    const db = new DB('core', {})
    const bookmarkDB = new DB('bookmarks', {})
    const { closeModal, setModal } = useModal()
    const { t } = useI18n()
    const { productsCheck, coursesAvailable } = useAccessCheck()
    const router = useRouter()
    const appInitialized = ref(false)
    const {
      hasAnyCourse,
      hasManyCourses,
      getSavedSelectedCourse,
      setCurrentCourse,
    } = useCourseHandler()

    onBeforeMount(() => {
      initTenant(props.tenantConfig)
      initSWUpdateWatch()
      instance()
        .checkToken()
        .then(async () => {
          setToken()
          setUser()
          await productsCheck()
          if (!hasAnyCourse.value) {
            showNoAccessModal()
            appInitialized.value = true
            return
          }
          if (hasManyCourses.value && !getSavedSelectedCourse()) {
            router.push('/bytt-kurs')
            appInitialized.value = true
            return
          }
          if (getSavedSelectedCourse()) {
            if (coursesAvailable.value.includes(getSavedSelectedCourse())) {
              setCurrentCourse(getSavedSelectedCourse())
            } else {
              showNoAccessModal()
            }
          } else {
            setCurrentCourse(coursesAvailable.value[0])
          }
          appInitialized.value = true
        })
        .catch(() => {
          appInitialized.value = true
          router.push('/login')
        })
    })

    const showNoAccessModal = () => {
      setModal(
        'ModalAction',
        {
          title: t(
            hasAnyCourse.value
              ? 'MODAL_NO_ACCESS_TITLE'
              : 'MODAL_NO_COURSE_TITLE',
          ),
          content: t(
            hasAnyCourse.value
              ? 'MODAL_NO_ACCESS_CONTENT'
              : 'MODAL_NO_COURSE_CONTENT',
          ),
          type: 'primary',
          actions: {
            confirm: {
              title: t('MODAL_NO_ACCESS_CONFIRM_BUTTON'),
              type: 'primary',
              on() {
                window.location.href = 'https://trafikkopplaring.no/'
              },
            },
            cancel: {
              title: t('MODAL_NO_ACCESS_CANCEL_BUTTON'),
              type: 'primary-alt',
              on() {
                if (hasAnyCourse.value) {
                  router.push('/bytt-kurs')
                }
                closeModal()
              },
            },
          },
        },
        {
          closeBtn: true,
          canEscape: false,
        },
      )
    }

    onMounted(() => {
      document.body.addEventListener('keydown', function () {
        document.body.classList.add('using-keyboard')
      })
      document.body.addEventListener('mousedown', function () {
        document.body.classList.remove('using-keyboard')
      })
    })

    watch(
      () => updateExists.value,
      (val) => {
        if (val) {
          setModal('ModalAction', {
            title: t(`MODAL_SW_TITLE`),
            content: t(`MODAL_SW_DESC`),
            type: 'primary',
            actions: {
              confirm: {
                title: t(`MODAL_SW_BUTTON`),
                type: 'primary-alt',
                on() {
                  refreshApp()
                  closeModal()
                },
              },
            },
          })
        }
      },
    )

    watch(
      () => user.value,
      async (curr, prev) => {
        if (curr && !prev) {
          await db.initSync({
            live: true,
            filter: (doc: any) => doc._id.startsWith('trafikk-'),
          })
          await bookmarkDB.initSync({
            live: true,
            filter: (doc: any) =>
              doc._id.startsWith('trafikk-') && doc._id.includes('-bookmark-'),
          })
          fetchChapters()
          const courseLessonQuestions = await getCourseLessonsProgress()
          const commonLessonQuestions = await getCommonLessonsProgress()
          const rows = [
            ...courseLessonQuestions.rows,
            ...commonLessonQuestions.rows,
          ]
          rows.forEach((lq) => {
            setLessonQuestions(lq.doc.lessonQuestions)
          })
        }
      },
    )

    return {
      appInitialized,
    }
  },
})
</script>

<style lang="scss">
@import './assets/icons/style.css';
#app {
  color: $color-text;
  font-family: $font-family;
  width: 100%;
  max-width: $app-max-width;
  margin: auto;

  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .katex {
    font-size: 1.45rem !important;
  }
}

a,
button,
input {
  @include a11yOutline();
}

a {
  color: $color-8;
  font-size: rem(16px);
  font-weight: 400;
  &:hover {
    font-weight: bold;
  }
}

button {
  // Disable double-tap zoom in Safari iOS 13+
  touch-action: manipulation;
}

.tippy-box[data-theme~='trafikk'] {
  font-family: $font-family;
  background-image: linear-gradient($color-3, $color-9);
  color: white;
  padding: rem(8px);
  text-align: center;
  white-space: pre-line;
  .tippy-arrow {
    color: $color-9;
  }
}
</style>
