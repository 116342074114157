<template>
  <div
    class="c-sidebar__overlay"
    :class="[{ 'c-sidebar__overlay--visible': isOpen }]"
    @click="closeSidebar"
  ></div>
  <nav class="c-sidebar" :class="[{ 'c-sidebar--visible': isOpen }]">
    <focus-trap v-model:active="isOpen">
      <div class="c-sidebar__wrapper">
        <TheButton
          icon="close"
          class="c-topbar__icon c-topbar__icon-close"
          :aria-label="[isOpen ? $t('CLOSE_MENU') : '']"
          :aria-expanded="[isOpen ? 'true' : 'false']"
          @click.prevent="closeSidebar"
          @keyup.esc="closeSidebar"
        >
        </TheButton>
        <SidebarMenu @close-menu="closeMenu" />
        <TheButton class="c-sidebar__logout-btn" @click="logout">{{
          $t('LOGOUT_BTN')
        }}</TheButton>
      </div>
    </focus-trap>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { isOpen, closeSidebar } from '@/composables/useSidebar'
import { useAuth } from '@/core'
import { useRouter } from 'vue-router'
import TheButton from '@/components/TheButton.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'

export default defineComponent({
  name: 'Sidebar',

  components: {
    TheButton,
    SidebarMenu,
  },

  setup() {
    const auth = useAuth()
    const router = useRouter()
    const closeMenu = (to: string): void => {
      router.push(to)
      closeSidebar()
    }
    const logout = (): void => {
      closeSidebar()
      auth.logout()
    }

    return {
      isOpen,
      closeSidebar,
      closeMenu,
      logout,
    }
  },
})
</script>

<style lang="scss">
.c-sidebar {
  position: fixed;
  top: $topbar-height;
  bottom: 0;
  left: -($menu-width + 2px);
  width: $menu-width;
  background-color: $menu-background;
  border-right: 1px solid $menu-border;
  color: $color-text-2;
  transition: left 250ms ease-in-out;
  z-index: 100;
  &--visible {
    left: 0;
  }
  &__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($menu-background, 0.8);
    visibility: hidden;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    &--visible {
      visibility: visible;
      opacity: 1;
    }
  }
  &__logout-btn {
    @include btnReset();
    @include a11yOutlineAlt();

    position: absolute;
    bottom: rem(44px);
    left: 0;
    right: 0;
    width: 50%;
    margin: auto;
  }
  .c-topbar__icon {
    margin-left: 1rem;
    &-close {
      position: relative;
      top: rem(-50px);
    }
  }

  .vb-icon-close {
    position: relative;
    top: 2px;
    font-size: rem(44px);
  }
}
</style>
