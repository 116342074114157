
import { defineComponent, onMounted, ref } from 'vue'

import useModal from '@/composables/useModal'
import ModalWrapper from '@/components/ModalWrapper.vue'
import Image from '@/components/Image.vue'
import { useAssets, utils } from '@/core'

export default defineComponent({
  components: {
    ModalWrapper,
    Image,
  },

  setup() {
    const { modalContent, closeModal } = useModal()
    const { fetchAsset, getAssetById } = useAssets()
    const { addCloudinaryUrlParams } = utils
    const zoomMaxScale = ref(1)

    const videoSrc = ref('')

    onMounted(async () => {
      if (modalContent.value.media.type === 'video') {
        await fetchAsset(modalContent.value.media.video.videoId)
        const video = getAssetById(modalContent.value.media.video.videoId)

        videoSrc.value = addCloudinaryUrlParams(video.url, 'q_auto')
      }

      if (window.matchMedia('(max-width: 1024px)').matches) {
        zoomMaxScale.value = 5
      }
    })

    return {
      modalContent,
      closeModal,
      videoSrc,
      zoomMaxScale,
    }
  },
})
