
import { defineComponent, PropType } from 'vue'
import useModal from '@/composables/useModal'
import Image from '@/components/Image.vue'
import TheButton from '@/components/TheButton.vue'
import { QuestionAssets } from 'types/interface/ComposeQuestion'

export default defineComponent({
  components: {
    Image,
    TheButton,
  },

  props: {
    media: {
      type: Object as PropType<QuestionAssets>,
    },
  },

  setup(props) {
    const { setModal } = useModal()

    const onMediaModalOpen = () =>
      setModal('ModalMedia', {
        content: '',
        media: props.media,
        type: 'media',
      })

    return {
      onMediaModalOpen,
    }
  },
})
