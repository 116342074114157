import { ref, computed } from 'vue'
import { useAuth, useTenant } from '@/core'
import DB from '@/core/services/db'
import useCourseHandler from '@/composables/useCourseHandler'

const db = new DB('bookmarks', {})
const { user } = useAuth()
const { tenant } = useTenant()
const { currentCourse } = useCourseHandler()

const bookmarkPrefix = computed(
  () =>
    `${tenant.value.slug}-${user.value._id}-bookmark-${currentCourse.value}`,
)
const bookmarksToDisplay = ref([])

const saveBookmarkContent = async (
  id: string,
  title: string,
  slugpath: string,
  lessonId: string,
  sectionId: string,
) => {
  try {
    const res = await db.createOrUpdate({
      _id: id,
      title: title,
      slugPath: slugpath,
      lessonId: lessonId,
      sectionId: sectionId,
    })
    return res
  } catch (err) {
    console.log(err)
    return null
  }
}

const isBookmarkActive = async (idx: string) => {
  try {
    const res = await db.getAllData({})

    return res.rows.some((ids: any) => {
      return ids.id === idx
    })
  } catch (err) {
    return false
  }
}

const getSavedBookmarks = async () => {
  try {
    const res = await db.getAllData({
      include_docs: true,
      startkey: bookmarkPrefix.value,
      endkey: `${bookmarkPrefix.value}\ufff0`,
    })
    bookmarksToDisplay.value = res.rows

    return bookmarksToDisplay.value
  } catch (err) {
    return null
  }
}

const removeBookmark = async (id: string) => {
  try {
    await db.removeData(id)
    return true
  } catch (err) {
    return null
  }
}

export {
  saveBookmarkContent,
  removeBookmark,
  isBookmarkActive,
  getSavedBookmarks,
  bookmarksToDisplay,
  bookmarkPrefix,
}
