import { ComposeModal, ModalContent, ModalOptions } from 'types/interface/Modal'
import { ref } from 'vue'

const isModalOpen = ref(false)
const modalComponent = ref('')
const modalContent = ref(null)
const modalOptions = ref<ModalOptions>({})
const onClose = (): void => {
  isModalOpen.value = false
  modalContent.value = null
  modalOptions.value = null
  modalComponent.value = ''
  document.documentElement.style.overflow = 'auto'
  window.removeEventListener('keydown', onEsc)
}
const onEsc = (ev: KeyboardEvent): void => {
  if (ev.key === 'Escape') {
    onClose()
  }
}
const setModal = (
  component: string,
  content: ModalContent,
  options: ModalOptions = {},
): void => {
  isModalOpen.value = true
  modalComponent.value = component
  modalContent.value = content
  modalOptions.value = { canEscape: true, closeBtn: false, ...options }
  document.documentElement.style.overflow = 'hidden'
  if (modalOptions.value.canEscape) {
    window.addEventListener('keydown', onEsc)
  }
}
const closeModal = (): void => {
  onClose()
}

export default (): ComposeModal => ({
  isModalOpen,
  modalComponent,
  modalContent,
  modalOptions,
  setModal,
  closeModal,
})
