
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    answer: Object,
    selected: Boolean,
    showResult: Boolean,
    disabled: Boolean,
  },
})
