import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import useCourseHandler from '@/composables/useCourseHandler'
import { beforeEach } from '@/core/router'
import useAccessCheck from '@/composables/useAccessCheck'
const { hasAnyCourse } = useCourseHandler()
import { useAuth } from '@/core'
const { coursesAvailable, productsCheck } = useAccessCheck()
const { instance, setToken, setUser } = useAuth()

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresAuth: true,
      requiresAccess: false,
    },
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
  },
  {
    path: '/:set/kapitler',
    alias: ['/:set/chapter-list'],
    name: 'ChapterList',
    meta: {
      requiresAuth: true,
      requiresAccess: true,
    },
    component: () =>
      import(/* webpackChunkName: "chapter-list" */ '@/views/ChapterList.vue'),
  },
  {
    path: '/test/:set/:question?',
    name: 'Test',
    meta: {
      requiresAuth: true,
      requiresAccess: true,
    },
    component: () => import(/* webpackChunkName: "test" */ '@/views/Test.vue'),
  },
  {
    path: '/test/:set/results/:resultsTimestamp',
    name: 'Results',
    meta: {
      requiresAuth: true,
      requiresAccess: true,
    },
    component: () =>
      import(/* webpackChunkName: "results" */ '@/views/Results.vue'),
  },
  {
    path: '/:set/progress',
    name: 'Progress',
    meta: {
      requiresAuth: true,
      requiresAccess: true,
    },
    component: () =>
      import(/* webpackChunkName: "progress" */ '@/views/Progress.vue'),
  },
  {
    path: '/leksjon/:slugPath*',
    alias: ['/lesson/:slugPath*'],
    name: 'Lesson',
    meta: {
      requiresAuth: true,
      requiresAccess: true,
    },
    component: () =>
      import(/* webpackChunkName: "lesson" */ '@/views/Lesson.vue'),
  },
  {
    path: '/kapittel/:slugPath*',
    alias: ['/chapter/:slugPath*'],
    name: 'Chapter',
    meta: {
      requiresAuth: true,
      requiresAccess: true,
    },
    component: () =>
      import(/* webpackChunkName: "chapter" */ '@/views/Chapter.vue'),
  },
  {
    path: '/side/:slugPath*',
    name: 'Single',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "single" */ '@/views/Single.vue'),
  },
  {
    path: '/:set/saved',
    name: 'Saved',
    meta: {
      requiresAuth: true,
      requiresAccess: true,
    },
    component: () =>
      import(/* webpackChunkName: "saved" */ '@/views/Saved.vue'),
  },
  {
    path: '/bytt-kurs',
    name: 'CoursePicker',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "course-picker" */ '@/views/CoursePicker.vue'
      ),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  },
  routes,
})

router.beforeEach(beforeEach)
router.beforeEach((to, from, next) => {
  if (to.meta?.requiresAccess) {
    instance()
      .checkToken()
      .then(async () => {
        setToken()
        setUser()
        if (!coursesAvailable.value.length) {
          await productsCheck()
        }
        const requestedCourse = to.params?.set || to.path.split('/')[2]
        if (!hasAnyCourse.value) {
          next({ name: 'Home' })
        } else if (requestedCourse === 'common') {
          next()
        } else if (
          !requestedCourse ||
          !coursesAvailable.value.includes(requestedCourse)
        ) {
          next({ name: 'CoursePicker' })
        }
        next()
      })
  } else {
    next()
  }
})

export default router
