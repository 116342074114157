import { utils } from '@/core'
import { Config } from 'types/interface/Config'

const customDataByEnv = {
  namespace: {
    production: 'e7c15b23-df80-485b-afdf-5230e426869c',
    development: 'c6c86e17-4616-4b5f-84d7-a42ea09a2135',
  },
  testTime: {
    production: 3600000,
    development: 190000,
  },
  passingScore: {
    production: 38,
    development: 5,
  },
  twoWeeksSubscription: {
    lastebil: {
      production: '146d0dca-ec5d-4c0f-aa79-b1f1a9d270d6',
      development: '480248f4-2807-4812-b09e-a4f290a97cf1',
    },
    buss: {
      production: 'c18ecfa7-d162-4e87-97b2-64a8c49b78f4',
      development: 'c18ecfa7-d162-4e87-97b2-64a8c49b78f4',
    },
  },
  oneMonthSubscription: {
    lastebil: {
      production: '2b0932df-b39b-4052-b9fa-a66751c3c3fe',
      development: '2b0932df-b39b-4052-b9fa-a66751c3c3fe',
    },
    buss: {
      production: '31c54c79-d4fa-4b08-ab86-2bdf22d63673',
      development: 'f6274026-af52-4914-b82f-821287c7396f',
    },
  },
  threeMonthsSubscription: {
    lastebil: {
      production: '8f6d63ec-9b4d-45ca-acfc-2bdd06016f5e',
      development: '88c9e650-5ce6-4527-b178-698aa0c8979b',
    },
    buss: {
      production: 'a554a91c-c963-4d8d-938f-4e2ad36e7b9a',
      development: '5f90d97c-bff6-4cff-8bd8-3fec522ddbfe',
    },
  },
  twelveMonthsSubscription: {
    lastebil: {
      production: '2f593af9-ebe2-47f4-b6f2-7187f57faae0',
      development: '',
    },
    buss: {
      production: '',
      development: '',
    },
  },
}

const config: Config = {
  name: 'Trafikk',
  slug: 'trafikk',
  namespace: utils.getDataByEnv('namespace', customDataByEnv),
  analytics: {
    gtm: 'GTM-KH5C7LW',
  },
  menu: {
    nb: [
      {
        title: 'Bytt kurs',
        to: '/bytt-kurs',
        showAction: 'hasManyCourses',
      },
      {
        title: 'Personvern',
        to: '/side/trafikk/personvern',
      },
      {
        title: 'Kjøpsvilkår',
        to: '/side/trafikk/kjopsvilkar',
      },
      {
        title: 'Informasjonskapsler',
        to: '/side/trafikk/informasjonskapsler',
      },
    ],
  },
  courses: {
    lastebil: {
      name: 'Lastebil',
      cover:
        'https://res.cloudinary.com/edtech/image/upload/h_150/v1654865936/coss_prod/e7c15b23-df80-485b-afdf-5230e426869c/57f6b454c8ff5616055850780cefb57240ae0edd9a132e268adec2f6cfb62a65.jpg',
    },
    buss: {
      name: 'Class B',
      cover:
        'https://res.cloudinary.com/edtech/image/upload/h_150/v1654865975/coss_prod/e7c15b23-df80-485b-afdf-5230e426869c/beab4e7c2205f3930b63221874ee0eec9eed710147e0613902422dcf3930fd68.jpg',
    },
  },
  //provide slugs for each category of subsets (value serves as category)
  questions: {
    common: {
      level1: 'test/common/level1',
      level2: 'test/common/level2',
      level3: 'test/common/level3',
      level4: 'test/common/level4',
    },
    buss: {
      level1: 'test/buss/level1',
      level2: 'test/buss/level2',
      level3: 'test/buss/level3',
      level4: 'test/buss/level4',
    },
    lastebil: {
      level1: 'test/lastebil/level1',
      level2: 'test/lastebil/level2',
      level3: 'test/lastebil/level3',
      level4: 'test/lastebil/level4',
    },
  },
  //compose test type sets from subsets, key corresponds with url (i.e. test/buss)
  questionSets: {
    lastebil: { common: 'common', specific: 'lastebil' },
    buss: { common: 'common', specific: 'buss' },
  },
  testLogic: {
    passingScore: utils.getDataByEnv('passingScore', customDataByEnv),
    testTime: utils.getDataByEnv('testTime', customDataByEnv),
    warningTimes: [600000, 180000, 60000],
    //provide expected number of questions for each category based in form weight:num of questions
    questionSpread: {
      common: {
        level1: { '1': 1, '2': 3 },
        level2: { '1': 3, '2': 4 },
        level3: { '1': 3, '2': 4 },
        level4: { '1': 1, '2': 3 },
      },
      specific: {
        level1: { '1': 1, '2': 2 },
        level2: { '1': 4, '2': 4 },
        level3: { '1': 3, '2': 5 },
        level4: { '1': 1, '2': 3 },
      },
    },
  },
  teacherEmail: 'trafikk@fagbokforlaget.no',
  assets: {},
  styles: [],
  eportalProducts: [
    {
      id: utils.getDataByEnv('lastebil', customDataByEnv.twoWeeksSubscription),
      product: 'lastebil',
      type: 'subscription',
      durationTime: 2,
      durationType: 'week',
    },
    {
      id: utils.getDataByEnv('lastebil', customDataByEnv.oneMonthSubscription),
      product: 'lastebil',
      type: 'subscription',
      durationTime: 1,
      durationType: 'month',
    },
    {
      id: utils.getDataByEnv(
        'lastebil',
        customDataByEnv.threeMonthsSubscription,
      ),
      product: 'lastebil',
      type: 'subscription',
      durationTime: 3,
      durationType: 'month',
    },
    {
      id: utils.getDataByEnv(
        'lastebil',
        customDataByEnv.twelveMonthsSubscription,
      ),
      product: 'lastebil',
      type: 'subscription',
      durationTime: 12,
      durationType: 'month',
    },
    {
      id: utils.getDataByEnv('buss', customDataByEnv.twoWeeksSubscription),
      product: 'buss',
      type: 'subscription',
      durationTime: 2,
      durationType: 'week',
    },
    {
      id: utils.getDataByEnv('buss', customDataByEnv.oneMonthSubscription),
      product: 'buss',
      type: 'subscription',
      durationTime: 1,
      durationType: 'month',
    },
    {
      id: utils.getDataByEnv('buss', customDataByEnv.threeMonthsSubscription),
      product: 'buss',
      type: 'subscription',
      durationTime: 3,
      durationType: 'month',
    },
  ],
}

export default config
