<template>
  <ModalWrapper
    v-if="modalContent"
    :title="modalContent.title"
    :closeBtn="modalOptions.closeBtn"
  >
    <template v-slot:content>
      <div class="c-modal-action__content" v-html="modalContent.content" />
    </template>
    <template v-slot:footer>
      <TheButton
        v-if="modalContent.actions.cancel"
        class="c-modal-action__btn c-modal-action__btn--cancel"
        :type="modalContent.actions.cancel.type || 'primary-alt'"
        @click="modalContent.actions.cancel.on"
        >{{ modalContent.actions.cancel.title }}</TheButton
      >
      <TheButton
        v-if="modalContent.actions.confirm"
        class="c-modal-action__btn c-modal-action__btn--confirm"
        :type="modalContent.actions.confirm.type || 'primary'"
        @click="modalContent.actions.confirm.on"
        >{{ modalContent.actions.confirm.title }}</TheButton
      >
    </template>
  </ModalWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import useModal from '@/composables/useModal'
import TheButton from '@/components/TheButton.vue'
import ModalWrapper from '@/components/ModalWrapper.vue'

export default defineComponent({
  components: {
    TheButton,
    ModalWrapper,
  },

  setup() {
    const { modalContent, modalOptions } = useModal()

    return {
      modalContent,
      modalOptions,
    }
  },
})
</script>

<style lang="scss">
.c-modal-action__content {
  line-height: rem(28px);
  padding: rem(22px) rem(16px) rem(52px);
}

.c-modal-action__footer {
  display: flex;
  justify-content: center;
  padding: 0 rem(16px) rem(16px);
}

.c-modal-action__btn {
  font-size: rem(16px);
  width: 50%;
  max-width: rem(175px);
  padding: rem(16px);

  &--cancel {
    margin-right: rem(16px);
  }

  &--confirm {
    margin-left: auto;
  }

  &:only-child {
    align-self: center;
    margin: auto;
  }
}
</style>
