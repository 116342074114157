
import { defineComponent, ref, onMounted } from 'vue'
import { useAssets, utils } from '@/core'
export default defineComponent({
  name: 'ChapterCard',

  props: {
    chapter: Object,
  },

  setup(props) {
    const coverURL = ref('')
    const { fetchAsset, getAssetById } = useAssets()
    const { addCloudinaryUrlParams } = utils
    onMounted(async () => {
      if (props.chapter.cover) {
        await fetchAsset(props.chapter.cover)
        const image = getAssetById(props.chapter.cover)
        coverURL.value = addCloudinaryUrlParams(image.url, 'h_150')
      }
    })
    return {
      coverURL,
    }
  },
})
