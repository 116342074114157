<template>
  <div class="c-test-media">
    <div v-if="media.type === 'video'" class="c-test-media__video">
      <Image
        class="c-test-media__video-img"
        :image-id="
          media.video.images &&
          media.video.images[0] &&
          media.video.images[0].imageId
        "
        :alt-text="
          media.video.images &&
          media.video.images[0] &&
          media.video.images[0].altText
        "
      />
      <TheButton class="c-test-media__play-btn" @click="onMediaModalOpen" />
    </div>

    <div v-else class="c-test-media__image">
      <Image
        class="c-test-media__image-img"
        :image-id="media.image && media.image.imageId"
        :alt-text="media.image && media.image.altText"
      />
      <TheButton class="c-test-media__btn" @click="onMediaModalOpen">
        <img
          src="@/assets/svg/resize.svg"
          alt=""
          class="c-test-media__btn-icon"
        />
        <span class="c-test-media__btn-text">{{ $t('TEST_MEDIA_BTN') }}</span>
      </TheButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import useModal from '@/composables/useModal'
import Image from '@/components/Image.vue'
import TheButton from '@/components/TheButton.vue'
import { QuestionAssets } from 'types/interface/ComposeQuestion'

export default defineComponent({
  components: {
    Image,
    TheButton,
  },

  props: {
    media: {
      type: Object as PropType<QuestionAssets>,
    },
  },

  setup(props) {
    const { setModal } = useModal()

    const onMediaModalOpen = () =>
      setModal('ModalMedia', {
        content: '',
        media: props.media,
        type: 'media',
      })

    return {
      onMediaModalOpen,
    }
  },
})
</script>

<style lang="scss">
.c-test-media {
  display: block;
  position: relative;
  background-color: $test-media-bg;

  &::after {
    content: '';
    display: block;
    padding-bottom: 27%;
  }

  &__image,
  &__video {
    position: absolute;
    width: 100%;
    height: 100%;
    &-img,
    &-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__play-btn {
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
    width: 1rem;
    height: 1rem;
    background: transparent;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 0;
    border-width: 0.75rem 0 0.75rem 1.5rem;
    border-color: transparent transparent transparent $btn-video-play;
    padding: 0;

    &::before {
      content: '';
      position: absolute;
      top: -1.75rem;
      left: -2.75rem;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      border: 0.25rem solid $btn-video-play;
    }
  }

  &__btn {
    @include btnReset();

    position: absolute;
    right: rem(24px);
    bottom: rem(8px);
    padding: rem(8px);
    border-radius: rem(6px);
    z-index: 1;
    display: flex;
    align-items: center;
    background-color: $btn-test-media-bg;
    box-shadow: 0 0 rem(16px) rem(1px) $answer-shadow;

    &-icon {
      display: inline-block;
      margin-right: rem(6px);
    }

    &-text {
      font-size: rem(16px);
      font-weight: 700;
      color: $btn-test-media-text;
    }
  }
}
</style>
