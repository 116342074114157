import { utils } from '@/core'
import { transformTables } from '@/core/utils/tables'

import katex from 'katex'

const { markdownToHtml } = utils

const renderKatex = (el: HTMLElement) => {
  Array.from(el.querySelectorAll('.katex')).forEach((k) => {
    k.innerHTML = katex.renderToString(k.innerHTML, {
      throwOnError: false,
    })
  })
}

const mdHtml = {
  beforeMount: function (el: HTMLElement, binding: { value: string }): void {
    el.innerHTML = markdownToHtml(binding.value)
    el.addEventListener('click', (e: MouseEvent) => {
      const targetElement = e.target as HTMLElement

      if (targetElement.getAttribute('data-concept-id')) {
        const conceptId = targetElement.getAttribute('data-concept-id')

        el.dispatchEvent(
          new CustomEvent('concept-click', { detail: { conceptId } }),
        )
      }
    })
    renderKatex(el)
    transformTables(el)
  },

  beforeUpdate: function (el: HTMLElement, binding: { value: string }): void {
    el.innerHTML = markdownToHtml(binding.value)
    renderKatex(el)
    transformTables(el)
  },
}

export default mdHtml
