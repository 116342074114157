
import { ref, computed, defineComponent, onMounted, watch } from 'vue'
import { useAssets, utils } from '@/core'

export default defineComponent({
  props: {
    imageId: {
      type: String,
      required: true,
    },
    altText: String,
    caption: String,
    copyright: String,
  },

  setup(props) {
    const { fetchAsset, getAssetById } = useAssets()
    const { addCloudinaryUrlParams } = utils
    const imageSrc = ref<string>('')
    const getAltText = computed(() => props.altText || '')

    const fetchImage = async () => {
      await fetchAsset(props.imageId)
      const image = getAssetById(props.imageId)
      imageSrc.value = /\.(gif)$/.test(image.url)
        ? image.url
        : addCloudinaryUrlParams(image.url, 'w_1920')
    }

    onMounted(() => {
      fetchImage()
    })

    watch(props, () => {
      imageSrc.value =
        'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
      fetchImage()
    })

    return {
      imageSrc,
      getAltText,
    }
  },
})
