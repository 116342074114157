<template>
  <Topbar :backIcon="null" />
  <div class="v-home">
    <div class="v-home__progress-card">
      <h2 class="v-home__progress-card__header">
        {{ $t('HOME_PROGRESS_TITLE') }}
      </h2>
      <div class="v-home__progress-card__desc">
        {{ $t('HOME_PROGRESS_DESC') }}
      </div>
      <div class="v-home__progress-card__image" />
      <TheButton
        :disabled="!courseAvailable"
        :to="courseAvailable ? `/${currentCourse}/progress` : ''"
        type="primary-alt"
        class="v-home__progress-card__button"
        >{{ $t('HOME_PROGRESS_BUTTON') }}</TheButton
      >
    </div>
    <div v-if="courseAvailable" class="v-home__course">
      <div class="v-home__course__heading">
        <h2 class="v-home__course__header">
          {{ $t('HOME_COURSE') }}
        </h2>
        <router-link
          :to="courseAvailable ? `/${currentCourse}/chapter-list` : ''"
          class="v-home__course__see-all"
        >
          {{ $t('HOME_COURSE_SEE_ALL') }}
        </router-link>
      </div>
      <div class="v-home__course__chapter-list">
        <ChapterCard
          v-for="chapter of chapters"
          :key="chapter.slug"
          :chapter="chapter"
        />
      </div>
    </div>
    <div class="v-home__saved">
      <div class="v-home__saved__heading">
        <h2 class="v-home__saved__header">
          {{ $t('HOME_SAVED_HEADER') }}
        </h2>
      </div>
      <div class="v-home__saved__container" v-if="courseAvailable">
        <div v-if="preselectedQuestions" class="v-home__saved__item">
          <router-link
            class="v-home__saved__item-link"
            :to="courseAvailable ? `/${currentCourse}/saved?tab=questions` : ''"
          >
            <img
              src="@/assets/svg/saved-questions.svg"
              alt=""
              class="v-home__saved__item-icon"
            />
            {{ amountOfQuestions }}</router-link
          >
        </div>
        <div v-if="preselectedLessons" class="v-home__saved__item">
          <router-link
            class="v-home__saved__item-link v-home__saved__item-link--lesson"
            :to="courseAvailable ? `/${currentCourse}/saved?tab=lessons` : ''"
          >
            <i
              class="vb-icon vb-icon-bookmark-outline v-home__saved__item-icon"
            ></i>
            <span class="v-home__saved__item-text">
              {{ amountOfLessons }}</span
            ></router-link
          >
        </div>
      </div>
    </div>
    <div class="v-home__fixed-container">
      <TheButton
        :disabled="!courseAvailable"
        class="v-home__test-btn"
        type="primary"
        @click="testBtnClick"
      >
        {{ $t('HOME_BTN_TAKE_EXAM') }}
      </TheButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import useChapters from '@/composables/useChapters'
import useModal from '@/composables/useModal'
import TheButton from '@/components/TheButton.vue'
import Topbar from '@/components/Topbar.vue'
import ChapterCard from '@/components/ChapterCard.vue'
import { getPreselectedQuestions } from '@/services/results'
import { getSavedBookmarks } from '@/services/bookmarkContent'
import useCourseHandler from '@/composables/useCourseHandler'
import useAccessCheck from '@/composables/useAccessCheck'

export default defineComponent({
  name: 'Home',

  components: {
    TheButton,
    Topbar,
    ChapterCard,
  },

  setup() {
    const { closeModal, setModal } = useModal()
    const router = useRouter()
    const { chapters } = useChapters()
    const { t } = useI18n()
    const { tenant } = useTenant()
    const { coursesAvailable } = useAccessCheck()
    const { currentCourse } = useCourseHandler()

    const preselectedQuestions = ref<number>(null)
    const preselectedLessons = ref<number>(null)

    const amountOfQuestions = computed(() =>
      t('HOME_SAVED_QUESTIONS', [preselectedQuestions.value]),
    )
    const amountOfLessons = computed(() =>
      t('HOME_SAVED_LESSONS', [preselectedLessons.value]),
    )

    const courseAvailable = computed(() =>
      coursesAvailable.value.includes(currentCourse.value),
    )

    const testBtnClick = (): void => {
      setModal('ModalAction', {
        title: 'Prøveeksamen',
        content:
          'Dette er prøveeksamen for alt innhold i kurset. Vær sikker på at du har gått gjennom alle leksjonene før du begynner. Prøven har 45 spørsmål. Du har 2 timer på deg til å fullføre. Det betyr at du har litt mer enn 2,5 minutt til å besvare hvert spørsmål. Du får se resultatet etter at du har fullført hele prøveeksamen. Lykke til!',
        type: 'primary',
        actions: {
          confirm: {
            title: 'Ta prøven',
            on() {
              router.push(`/test/${currentCourse.value}`)
              closeModal()
            },
          },
          cancel: {
            title: 'Nei, gå tilbake',
            on() {
              closeModal()
            },
          },
        },
      })
    }

    useHead({
      title: computed(() => `${tenant.value.name}`),
    })

    onBeforeMount(async () => {
      const preselected = await getPreselectedQuestions()
      preselectedQuestions.value = preselected
        ? preselected.questionIds.length
        : 0
      const preselectedBookmarks = await getSavedBookmarks()
      preselectedLessons.value = preselectedBookmarks
        ? preselectedBookmarks.length
        : 0
    })

    return {
      currentCourse,
      chapters,
      testBtnClick,
      amountOfQuestions,
      amountOfLessons,
      preselectedQuestions,
      preselectedLessons,
      courseAvailable,
    }
  },
})
</script>

<style lang="scss">
.v-home {
  min-height: calc(100vh - $topbar-height - $footer-height);
  width: 100%;
  max-width: inherit;
  box-shadow: 0 -0.25rem 0.5rem $app-container-outline;
  padding-top: rem($topbar-height);
  padding-bottom: rem($footer-height);
  &__fixed-container {
    position: fixed;
    background: $home__fixed-container--bg;
    height: rem($footer-height);
    max-height: rem($footer-height);
    width: 100%;
    max-width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    box-shadow: 0 -0.25rem 0.5rem $app-container-outline;
  }
  &__test-btn {
    max-width: 80vw;
    width: 20rem;
    max-height: rem(52px);
  }
  &__course {
    &__heading {
      margin-top: rem(32px);
      padding: 0 rem(32px);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__header {
      font-size: rem(32px);
      margin: 0;
    }
    &__see-all {
      font-weight: 700;
      color: $course-card-title;
    }
    &__chapter-list {
      display: flex;
      max-width: 100vw;
      overflow: auto;
      padding: rem(8px) rem(16px);

      @include bp(small) {
        flex-wrap: wrap;
      }
    }
  }

  &__progress-card {
    display: grid;
    grid-template-columns: 16px auto 10px 110px 24px;
    grid-template-rows: 16px auto auto 52px 16px;
    box-shadow: 0 0 1rem $app-container-outline;
    margin: rem(16px);
    border-radius: rem(16px);
    min-height: 175px;
    &__header {
      font-size: rem(32px);
      grid-column-start: 2;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 3;
      margin: 0;
    }

    &__desc {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 4;
      font-size: rem(16px);
    }

    &__button {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 4;
      grid-row-end: 5;
      max-width: 142px;
      color: $color-text;
    }
    &__desc,
    &__button {
      @include bp-down(small) {
        grid-column-end: 5;
      }
    }
    &__image {
      background-image: url('~@/assets/images/progress.jpg');
      background-size: cover;
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 5;
      border-radius: rem(12px);

      @include bp-down(small) {
        display: none;
      }
    }
  }
  &__saved {
    &__heading {
      margin-top: rem(32px);
      padding: 0 rem(32px);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__header {
      font-size: 2rem;
      margin: 0;
    }
    &__container {
      display: flex;
      align-items: center;
      overflow: auto;
      padding: 0.5rem 1rem;
    }

    &__item {
      display: flex;
      flex-direction: column;
      padding: 1rem 0.2rem;
      gap: 0.5rem;
      @include bp(xsmall) {
        padding: 1rem;
      }
      &-text {
        text-decoration: underline;
      }
    }
    &__item-link {
      font-weight: 700;
      color: $saved-item-title;
      &--lesson {
        text-decoration: none;
      }
    }
    &__item-icon {
      width: 1.5rem;
      margin: 0 0.75rem;
      vertical-align: middle;
    }
  }

  .vb-icon-bookmark-outline {
    font-size: rem(26px);
    color: $bookmark-outline;
  }
}
</style>
