
import { computed, ComputedRef, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useTenant } from '@/core'
import TheButton from '@/components/TheButton.vue'
import useCourseHandler from '@/composables/useCourseHandler'

export default defineComponent({
  name: 'SidebarMenu',

  components: {
    TheButton,
  },

  emits: ['close-menu'],

  setup(props, { emit }) {
    const { locale } = useI18n()
    const route = useRoute()
    const { tenant } = useTenant()
    const { hasManyCourses } = useCourseHandler()
    const menu = computed(() => tenant.value?.menu[locale.value])
    const closeMenu = (to: string): void => {
      emit('close-menu', to)
    }
    const showActions: {
      hasManyCourses: ComputedRef<boolean>
    } = {
      hasManyCourses: hasManyCourses,
    }
    const showAction = (action: 'hasManyCourses'): boolean => {
      if (action) {
        return showActions[action].value
      }

      return true
    }

    return {
      menu,
      route,
      showAction,
      closeMenu,
    }
  },
})
