<template>
  <ModalWrapper
    v-if="modalContent"
    :title="modalContent.title"
    :classes="modalContent.success ? 'success' : 'fail'"
  >
    <template v-slot:content>
      <TheHeading
        class="c-modal-results__heading"
        v-html="contentHeading"
      ></TheHeading>
      <div
        class="c-modal-results__question"
        v-html="modalContent.content.text"
      />
      <div
        class="c-modal-results__content"
        v-html="modalContent.content.explanation"
      />
      <div
        v-html="contentHint"
        v-if="modalContent.type !== 'feedbackOnly'"
        class="c-modal-results__hint"
      />
    </template>
    <template v-slot:footer>
      <div v-if="modalContent.success" class="c-modal-results__sfl-wrapper">
        <TheButton
          v-if="!savedForLater || savedQuestion"
          class="c-modal-results__btn c-modal-results__btn--sfl"
          type="primary-alt"
          @click="saveForLater"
          >{{
            $t(
              savedQuestion
                ? 'RESULTS_MODAL_BTN_KEEP_SAVED'
                : 'RESULTS_MODAL_BTN_TEXT_SFL',
            )
          }}
        </TheButton>
        <span
          v-else
          class="c-modal-results__saved-for-later"
          type="primary-alt"
        >
          <i class="vb-icon vb-icon-check" />{{ $t('RESULTS_MODAL_TEXT_SFL') }}
        </span>
      </div>
      <TheButton
        v-if="modalContent.actions.confirm"
        class="c-modal-results__btn c-modal-results__btn--confirm"
        type="primary"
        @click="modalContent.actions.confirm.on"
        >{{ modalContent.actions.confirm.title }}</TheButton
      >
    </template>
  </ModalWrapper>
</template>

<script lang="ts">
import { ref, computed, defineComponent, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'

import useModal from '@/composables/useModal'
import TheHeading from '@/components/TheHeading.vue'
import TheButton from '@/components/TheButton.vue'
import ModalWrapper from '@/components/ModalWrapper.vue'
import {
  setPreselectedQuestions,
  getPreselectedQuestions,
} from '@/services/results'
import useSaved from '@/composables/useSaved'

export default defineComponent({
  components: {
    TheHeading,
    TheButton,
    ModalWrapper,
  },

  setup() {
    const { t } = useI18n()
    const { modalContent } = useModal()
    const {
      preselectedCorrect,
      preselectedQuestions,
      preselectedQuestionsRev,
    } = useSaved()
    const { closeModal } = useModal()
    const savedQuestion = computed(
      () => modalContent.value.type === 'savedQuestion',
    )
    const questionId = computed(
      () =>
        modalContent.value.content.id || modalContent.value.content.questionId,
    )
    const savedForLater = computed<boolean>(() =>
      preselectedQuestions.value.includes(questionId.value),
    )
    const saveForLater = async () => {
      if (savedQuestion.value) {
        preselectedCorrect.value = [
          ...preselectedCorrect.value,
          questionId.value,
        ]
        setPreselectedQuestions(
          preselectedQuestions.value,
          preselectedCorrect.value,
          preselectedQuestionsRev.value,
        )
        closeModal()
      } else if (!savedForLater.value) {
        preselectedQuestions.value.push(questionId.value)
        preselectedCorrect.value.push(questionId.value)
        setPreselectedQuestions(
          preselectedQuestions.value,
          preselectedCorrect.value,
          preselectedQuestionsRev.value,
        )
      }
    }
    const contentHeading = computed(() =>
      modalContent.value.success
        ? t('RESULTS_CONTENT_HEADING_SUCCESS')
        : t('RESULTS_CONTENT_HEADING_FAIL'),
    )

    const contentHint = computed(() =>
      modalContent.value.success
        ? t('RESULTS_MODAL_HINT_SUCCESS')
        : t('RESULTS_MODAL_HINT_FAIL'),
    )

    return {
      modalContent,
      contentHeading,
      contentHint,
      savedForLater,
      saveForLater,
      savedQuestion,
    }
  },
})
</script>

<style lang="scss">
.c-modal-results__heading {
  margin: 0;
  margin-bottom: rem(20px);
  font-size: rem(32px);
  line-height: 1.4;

  .success & {
    span {
      color: $results-modal-success;
    }
  }

  .fail & {
    span {
      color: $results-modal-fail;
    }
  }
}

.c-modal-wrapper__content > div {
  margin: 0.75rem 0;
}

.c-modal-results__content {
  line-height: rem(28px);
}

.c-modal-results__question {
  font-weight: 700;
}

.c-modal-results__sfl-wrapper {
  width: 50%;
}

.c-modal-results__btn {
  font-size: rem(16px);
  max-width: rem(188px);
  padding: rem(16px);

  &--sfl {
    margin-right: rem(16px);
  }

  &--confirm {
    width: 50%;
    margin-left: auto;
  }
}

.c-modal-results__saved-for-later {
  font-size: rem(16px);
  font-weight: 700;

  i {
    font-size: rem(12px);
    margin-right: rem(5px);
    color: $results-modal-success;
  }
}
</style>
