
import { defineComponent } from 'vue'
import TheHeading from '@/components/TheHeading.vue'
import ElementText from '@/components/Elements/text.vue'
import ElementSection from '@/components/Elements/section.vue'

export default defineComponent({
  name: 'ContentRenderer',

  props: {
    title: String,
    contentItems: Array,
  },

  components: {
    TheHeading,
    ElementText,
    ElementSection,
  },

  setup() {
    const mapComponent = (type: string): string => {
      const types = {
        text: 'ElementText',
        section: 'ElementSection',
      }

      return types[type as keyof typeof types]
    }

    return {
      mapComponent,
    }
  },
})
