import { computed } from 'vue'
import DB from '@/core/services/db'
import { useAuth, useTenant } from '@/core'
import { UserAnswer } from 'types/interface/ComposeQuestion'
import router from '@/router'
import useCourseHandler from '@/composables/useCourseHandler'

const maxRevs = 11
const db = new DB('core', {
  revs_limit: maxRevs,
})
const { user } = useAuth()
const { tenant } = useTenant()
const { currentCourse } = useCourseHandler()
const resultId = computed<string>(
  () =>
    `${tenant.value.slug}-${user.value._id}-results-${router.currentRoute.value.params.set}`,
)
const preselectedRouterValue = computed(
  () =>
    currentCourse.value ||
    router.currentRoute.value.params.set ||
    (router.currentRoute.value.params.slugPath &&
      router.currentRoute.value.params.slugPath[0]),
)
const preselectedQuestionsId = computed<string>(
  () =>
    `${tenant.value.slug}-${user.value._id}-preselectedIDs-${preselectedRouterValue.value}`,
)
const saveResult = async (results: UserAnswer[]): Promise<number> => {
  try {
    const ATTEMPTS_LENGTH = 11
    const timestamp = Date.now()
    let prevResult
    try {
      prevResult = await getResult()
    } catch (err) {
      prevResult = null
    }

    const attempts = prevResult?.attempts || []
    if (attempts.length > ATTEMPTS_LENGTH) {
      attempts.shift()
    }
    attempts.push({
      timestamp,
      results,
    })
    await db.createOrUpdate<{
      _id: string
      attempts: {
        timestamp: number
        results: UserAnswer[]
      }[]
      createdAt: number
      rev?: string
    }>({
      _id: resultId.value,
      attempts,
      createdAt: timestamp,
    })

    return timestamp
  } catch (err) {
    console.error(err)
    return null
  }
}

const getResult = async (): Promise<{
  _id: string
  _rev: string
  createdAt: number
  attempts: {
    timestamp: number
    results: UserAnswer[]
  }[]
}> => {
  const res = await db.getData(resultId.value, {})

  return res
}

const setPreselectedQuestions = async (
  questionIds: string[],
  correctIds: string[],
  rev: string = null,
): Promise<string> => {
  try {
    const timestamp = Date.now()
    const res = await db.createOrUpdate<{
      _id: string
      questionIds: string[]
      correctIds: string[]
      createdAt: number
      rev?: string
    }>({
      _id: preselectedQuestionsId.value,
      questionIds,
      correctIds,
      createdAt: timestamp,
      rev,
    })

    return res.rev
  } catch (err) {
    console.error(err)
    return null
  }
}

const getPreselectedQuestions = async (): Promise<{
  _id: string
  _rev: string
  createdAt: number
  questionIds: string[]
  correctIds: string[]
}> => {
  try {
    const res = await db.getData(preselectedQuestionsId.value, {})
    return res
  } catch (err) {
    console.error(err)
    return null
  }
}

const clearResults = async (): Promise<void> => {
  await db.createOrUpdate<{
    _id: string
    results: UserAnswer[]
    createdAt: number
    rev?: string
  }>({
    _id: resultId.value,
  })
}

export {
  saveResult,
  getResult,
  setPreselectedQuestions,
  getPreselectedQuestions,
  clearResults,
}
