
enum BackIcon {
  BACK = 'back',
  CLOSE = 'close',
}

import { defineComponent, PropType } from 'vue'
import useCourseHandler from '@/composables/useCourseHandler'
import TheHeading from '@/components/TheHeading.vue'
import TheButton from '@/components/TheButton.vue'
import { isOpen, closeSidebar, openSidebar } from '@/composables/useSidebar'
const { hasAnyCourse } = useCourseHandler()

export default defineComponent({
  props: {
    headerConst: String,
    backUrl: {
      type: String,
      default: '',
    },
    backIcon: { type: String as PropType<BackIcon>, default: BackIcon.BACK },
  },
  components: {
    TheButton,
    TheHeading,
  },
  setup() {
    const toggleSidebar = () => {
      isOpen.value ? closeSidebar() : openSidebar()
    }

    const isCourseSelected = () => {
      if (
        localStorage.getItem('trafikk-selected-course') ||
        !hasAnyCourse.value
      ) {
        return '/'
      } else {
        return '/bytt-kurs'
      }
    }

    return {
      BackIcon,
      isOpen,
      closeSidebar,
      openSidebar,
      toggleSidebar,
      isCourseSelected,
    }
  },
})
