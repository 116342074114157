<template>
  <div class="c-content-renderer">
    <TheHeading v-if="title" class="c-content-renderer__title">{{
      title
    }}</TheHeading>
    <div
      v-if="contentItems && contentItems.length"
      class="c-content-renderer__wrapper"
    >
      <div
        class="c-content-renderer__element"
        :class="[`c-content-renderer__element--${item.contentType}`]"
        v-for="item in contentItems"
        :key="item.id"
      >
        <component
          v-if="item.contentData"
          :is="mapComponent(item.contentType)"
          :key="item.id"
          :data="item.contentData"
          :item="item.contentId"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TheHeading from '@/components/TheHeading.vue'
import ElementText from '@/components/Elements/text.vue'
import ElementSection from '@/components/Elements/section.vue'

export default defineComponent({
  name: 'ContentRenderer',

  props: {
    title: String,
    contentItems: Array,
  },

  components: {
    TheHeading,
    ElementText,
    ElementSection,
  },

  setup() {
    const mapComponent = (type: string): string => {
      const types = {
        text: 'ElementText',
        section: 'ElementSection',
      }

      return types[type as keyof typeof types]
    }

    return {
      mapComponent,
    }
  },
})
</script>

<style lang="scss">
.c-content-renderer {
  max-width: 1026px;
}

.c-content-renderer__title {
  font-size: 1.875rem;
  font-weight: 500;
}
</style>
