
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'TheButton',

  props: {
    to: { type: [String, Object] },
    type: {
      type: String,
      default: 'basic',
    },
    icon: {
      type: String,
      default: null,
    },
    iconPos: {
      type: String,
      default: 'left',
    },
    iconSet: {
      type: String,
      default: 'vb-icon',
    },
    hidden: Boolean,
    disabled: Boolean,
  },

  setup(props) {
    const currentComponent = computed(() => {
      if (props.to) {
        return 'router-link'
      } else {
        return 'button'
      }
    })
    const attributes = computed(() => {
      if (currentComponent.value === 'router-link') {
        return {
          to: props.to,
        }
      } else {
        return {}
      }
    })

    return {
      attributes,
      currentComponent,
    }
  },
})
