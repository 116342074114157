import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import i18n from '@/i18n'
import tenantConfig from '@/config/tenants/trafikk'
import 'normalize.css'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import VueZoomer from 'vue-zoomer'
import { FocusTrap } from 'focus-trap-vue'
import mdHtml from '@/directives/mdHtml'
import 'katex/dist/katex.css'
import VueGtm from '@gtm-support/vue-gtm'

const head = createHead()

createApp(App, {
  tenantConfig,
})
  .use(VueGtm, {
    id: tenantConfig.analytics.gtm,
  })
  .use(head)
  .use(i18n)
  .use(router)
  .use(VueTippy, {
    directive: 'tippy',
    component: 'tippy',
    componentSingleton: 'tippy-singleton',
  })
  .use(VueZoomer)
  .directive('md-html', mdHtml)
  .component('FocusTrap', FocusTrap)
  .mount('#app')
