import { createI18n } from 'vue-i18n'
import nb from '@/i18n/nb.json'
import nn from '@/i18n/nn.json'

const i18n = createI18n({
  locale: 'nb',
  messages: {
    nb: nb,
    nn: nn,
  },
})

export default i18n
