
import { defineComponent } from 'vue'

import useModal from '@/composables/useModal'
import TheButton from '@/components/TheButton.vue'
import ModalWrapper from '@/components/ModalWrapper.vue'

export default defineComponent({
  components: {
    TheButton,
    ModalWrapper,
  },

  setup() {
    const { modalContent, modalOptions } = useModal()

    return {
      modalContent,
      modalOptions,
    }
  },
})
