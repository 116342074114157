
import { ref, computed, defineComponent, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'

import useModal from '@/composables/useModal'
import TheHeading from '@/components/TheHeading.vue'
import TheButton from '@/components/TheButton.vue'
import ModalWrapper from '@/components/ModalWrapper.vue'
import {
  setPreselectedQuestions,
  getPreselectedQuestions,
} from '@/services/results'
import useSaved from '@/composables/useSaved'

export default defineComponent({
  components: {
    TheHeading,
    TheButton,
    ModalWrapper,
  },

  setup() {
    const { t } = useI18n()
    const { modalContent } = useModal()
    const {
      preselectedCorrect,
      preselectedQuestions,
      preselectedQuestionsRev,
    } = useSaved()
    const { closeModal } = useModal()
    const savedQuestion = computed(
      () => modalContent.value.type === 'savedQuestion',
    )
    const questionId = computed(
      () =>
        modalContent.value.content.id || modalContent.value.content.questionId,
    )
    const savedForLater = computed<boolean>(() =>
      preselectedQuestions.value.includes(questionId.value),
    )
    const saveForLater = async () => {
      if (savedQuestion.value) {
        preselectedCorrect.value = [
          ...preselectedCorrect.value,
          questionId.value,
        ]
        setPreselectedQuestions(
          preselectedQuestions.value,
          preselectedCorrect.value,
          preselectedQuestionsRev.value,
        )
        closeModal()
      } else if (!savedForLater.value) {
        preselectedQuestions.value.push(questionId.value)
        preselectedCorrect.value.push(questionId.value)
        setPreselectedQuestions(
          preselectedQuestions.value,
          preselectedCorrect.value,
          preselectedQuestionsRev.value,
        )
      }
    }
    const contentHeading = computed(() =>
      modalContent.value.success
        ? t('RESULTS_CONTENT_HEADING_SUCCESS')
        : t('RESULTS_CONTENT_HEADING_FAIL'),
    )

    const contentHint = computed(() =>
      modalContent.value.success
        ? t('RESULTS_MODAL_HINT_SUCCESS')
        : t('RESULTS_MODAL_HINT_FAIL'),
    )

    return {
      modalContent,
      contentHeading,
      contentHint,
      savedForLater,
      saveForLater,
      savedQuestion,
    }
  },
})
