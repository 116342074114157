
import { defineComponent } from 'vue'
import useModal from '@/composables/useModal'

import TheHeading from '@/components/TheHeading.vue'

import TheButton from '@/components/TheButton.vue'

export default defineComponent({
  components: {
    TheHeading,
    TheButton,
  },

  props: {
    title: String,
    classes: String,
    closeBtn: Boolean,
  },
  setup() {
    const { closeModal } = useModal()

    return {
      closeModal,
    }
  },
})
