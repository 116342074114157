import PouchDB from 'pouchdb'
import { fetchWithAuth } from '../api'

class DB {
  constructor(dbName = 'core', options = {}) {
    this.localDB = new PouchDB(dbName, options)
    this.sync = null
    this.id = null
  }

  initSync = async (syncOptions = {}) => {
    this.remoteDB = new PouchDB(`${process.env.VUE_APP_COSS_URL}/sync/proxy`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    const response = await fetchWithAuth('sync/user', {
      method: 'POST',
      headers: { 'content-type': 'text/plain' },
    })

    if (200 <= response.status < 300) {
      this.id = (await response.json()).id
      this.syncData(syncOptions)
      return this.id
    }
  }

  syncData = (options = {}) => {
    if (this.id) {
      this.sync = this.localDB.replicate.to(this.remoteDB, options)
      this.localDB.replicate.from(this.remoteDB, options)
    }
  }

  cancelSync = () => {
    this.sync?.cancel()
  }

  createOrUpdate = async (data) => {
    let doc

    if ('_id' in data) {
      try {
        const oldDoc = await this.localDB.get(data['_id'])

        data._rev = oldDoc._rev
        doc = await this.localDB.put(data)
      } catch (err) {
        doc = await this.localDB.put(data)
      }
    }

    return doc
  }

  getData = (id, options = {}) => {
    return this.localDB.get(id, options)
  }

  getAllData = (bulkIds) => {
    return this.localDB.allDocs(bulkIds)
  }

  removeData = async (id) => {
    const removeData = await this.localDB.remove(await this.getData(id))

    return removeData
  }

  onChanges = (options, callback) => {
    this.localDB.changes(options).on('change', (data) => callback(data))
  }
}

export default DB
