<template>
  <header class="c-topbar">
    <div class="c-topbar__wrapper">
      <nav class="c-topbar__nav" :class="[`c-topbar__nav-${backIcon}`]">
        <router-link
          v-if="backIcon"
          :to="backUrl || isCourseSelected()"
          class="c-topbar__icon"
        >
          <i
            class="vb-icon"
            :class="[
              backIcon === BackIcon.BACK
                ? 'vb-icon-chevron-left'
                : 'vb-icon-close',
            ]"
          />
        </router-link>
        <TheButton
          v-else
          icon="hamburger"
          :aria-label="[isOpen ? '' : $t('OPEN_MENU')]"
          :aria-expanded="[isOpen ? 'true' : 'false']"
          class="c-topbar__icon c-topbar__icon-hamburger"
          :class="{ 'c-topbar__icon--hidden': isOpen }"
          @click.prevent="toggleSidebar"
          @keyup.esc="closeSidebar"
        >
        </TheButton>
      </nav>
      <TheHeading v-if="headerConst" level="h1" class="c-topbar__heading">{{
        $t(headerConst)
      }}</TheHeading>
    </div>
  </header>
</template>

<script lang="ts">
enum BackIcon {
  BACK = 'back',
  CLOSE = 'close',
}

import { defineComponent, PropType } from 'vue'
import useCourseHandler from '@/composables/useCourseHandler'
import TheHeading from '@/components/TheHeading.vue'
import TheButton from '@/components/TheButton.vue'
import { isOpen, closeSidebar, openSidebar } from '@/composables/useSidebar'
const { hasAnyCourse } = useCourseHandler()

export default defineComponent({
  props: {
    headerConst: String,
    backUrl: {
      type: String,
      default: '',
    },
    backIcon: { type: String as PropType<BackIcon>, default: BackIcon.BACK },
  },
  components: {
    TheButton,
    TheHeading,
  },
  setup() {
    const toggleSidebar = () => {
      isOpen.value ? closeSidebar() : openSidebar()
    }

    const isCourseSelected = () => {
      if (
        localStorage.getItem('trafikk-selected-course') ||
        !hasAnyCourse.value
      ) {
        return '/'
      } else {
        return '/bytt-kurs'
      }
    }

    return {
      BackIcon,
      isOpen,
      closeSidebar,
      openSidebar,
      toggleSidebar,
      isCourseSelected,
    }
  },
})
</script>

<style lang="scss">
.c-topbar {
  background: $topbar-results-bg;
  color: $color-text-2;
  position: fixed;
  z-index: 100;
  height: rem($topbar-height);
  max-height: rem($topbar-height);
  left: 0;
  right: 0;
  top: 0;
  padding: 0 rem(8px);

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  &__nav {
    position: absolute;
    left: 0;
    &-close {
      right: 0;
      left: initial;
    }
  }

  &__heading {
    font-size: rem(32px);
  }

  &__icon,
  &__back {
    @include a11yOutlineAltWithOffset();
    display: inline-block;
    cursor: pointer;
    color: $color-text-2;
    text-decoration: none;
    font-size: rem(44px);
    line-height: 1;
    background-color: transparent;
    border: none;
    padding: 0;

    &-hamburger {
      visibility: visible;
      opacity: 1;
      transition: visibility 250ms ease-in-out, opacity 250ms ease-in-out;
    }

    &--hidden {
      visibility: hidden;
      opacity: 0;
    }
  }

  .vb-icon-hamburger {
    position: relative;
    top: rem(3px);
    font-size: rem(44px);
  }
}
</style>
